$background-main-image: none;
$background-main-position: center;
$background-main-size: cover;

//$background-image: url(../images/logo-ge-white.svg);
$background-image: url(../images/logo-white.svg);
$background-position: 2em 5em;
$background-size: 50%;

$color_background: #00a8ab;

$color_bright: #f9d50c;
$color_main: #0f5e60;
$color_accent: #cd4c6e;

$color_highlight: rgb(255, 128, 0);

$color_clickable: #a12d4c;

$color_success: #35b668;
$color_error: #c93534;

$main-font-family: "Lato";
