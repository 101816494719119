@import 'css/pure-min.css';
/*@import url('https://use.fontawesome.com/releases/v5.0.2/css/all.css');*/
@import 'css/main.scss';
@import 'css/csshake.min.css';

@import 'css/common_vars.scss';

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
}

body * {
  touch-action: manipulation;
}

#root .loading {
  position: absolute;
  width: 100%;
  height: 100%;
  //background: $color_background;
}